import XA from 'components/ExternalAnchor';
import ZoomImage from 'components/ZoomImage';
import jet from "../../../../src/content/posts/pitchfork-proxies-jet/jet.jpg";
import shineOn from "../../../../src/content/posts/pitchfork-proxies-jet/shine-on.jpg";
import shineOnZoom from "../../../../src/content/posts/pitchfork-proxies-jet/shine-on-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  ZoomImage,
  jet,
  shineOn,
  shineOnZoom,
  React
};