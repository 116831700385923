import ZoomImage from 'components/ZoomImage';
import index from "../../../../src/content/projects/personal/rotten-trumpatoes/index.jpg";
import indexZoom from "../../../../src/content/projects/personal/rotten-trumpatoes/index-zoom.jpg";
import movies from "../../../../src/content/projects/personal/rotten-trumpatoes/movies.jpg";
import moviesZoom from "../../../../src/content/projects/personal/rotten-trumpatoes/movies-zoom.jpg";
import scrape from "../../../../src/content/projects/personal/rotten-trumpatoes/scrape.jpg";
import scrapeZoom from "../../../../src/content/projects/personal/rotten-trumpatoes/scrape-zoom.jpg";
import * as React from 'react';
export default {
  ZoomImage,
  index,
  indexZoom,
  movies,
  moviesZoom,
  scrape,
  scrapeZoom,
  React
};