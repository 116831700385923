import XA from 'components/ExternalAnchor';
import ZoomImage from 'components/ZoomImage';
import alert from "../../../../src/content/posts/react-rust-wasm/alert.jpg";
import alertZoom from "../../../../src/content/posts/react-rust-wasm/alert-zoom.jpg";
import load from "../../../../src/content/posts/react-rust-wasm/load.jpg";
import loadZoom from "../../../../src/content/posts/react-rust-wasm/load-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  ZoomImage,
  alert,
  alertZoom,
  load,
  loadZoom,
  React
};