import XA from 'components/ExternalAnchor';
import ZoomImage from 'components/ZoomImage';
import ZoomCarousel from 'components/ZoomCarousel';
import Hidden from 'components/Hidden';
import x100vCardinal from "../../../../src/content/posts/hello-telephoto/x100v-cardinal.jpg";
import x100vCardinalZoom from "../../../../src/content/posts/hello-telephoto/x100v-cardinal-zoom.jpg";
import summerTanager from "../../../../src/content/posts/hello-telephoto/summer-tanager.jpg";
import cardinalPuff from "../../../../src/content/posts/hello-telephoto/cardinal-puff.jpg";
import cardinalPuffZoom from "../../../../src/content/posts/hello-telephoto/cardinal-puff-zoom.jpg";
import dove from "../../../../src/content/posts/hello-telephoto/dove.jpg";
import doveZoom from "../../../../src/content/posts/hello-telephoto/dove-zoom.jpg";
import sparrow from "../../../../src/content/posts/hello-telephoto/sparrow.jpg";
import sparrowZoom from "../../../../src/content/posts/hello-telephoto/sparrow-zoom.jpg";
import hawk from "../../../../src/content/posts/hello-telephoto/hawk.jpg";
import hawkZoom from "../../../../src/content/posts/hello-telephoto/hawk-zoom.jpg";
import gull from "../../../../src/content/posts/hello-telephoto/gull.jpg";
import gullZoom from "../../../../src/content/posts/hello-telephoto/gull-zoom.jpg";
import wonderWheel from "../../../../src/content/posts/hello-telephoto/wonder-wheel.jpg";
import wonderWheelZoom from "../../../../src/content/posts/hello-telephoto/wonder-wheel-zoom.jpg";
import barge from "../../../../src/content/posts/hello-telephoto/barge.jpg";
import bargeZoom from "../../../../src/content/posts/hello-telephoto/barge-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  ZoomImage,
  ZoomCarousel,
  Hidden,
  x100vCardinal,
  x100vCardinalZoom,
  summerTanager,
  cardinalPuff,
  cardinalPuffZoom,
  dove,
  doveZoom,
  sparrow,
  sparrowZoom,
  hawk,
  hawkZoom,
  gull,
  gullZoom,
  wonderWheel,
  wonderWheelZoom,
  barge,
  bargeZoom,
  React
};