import ZoomImage from 'components/ZoomImage';
import XA from 'components/ExternalAnchor';
import final from "../../../../src/content/projects/professional/textmoji/final.jpg";
import slack from "../../../../src/content/projects/professional/textmoji/slack.jpg";
import thx from "../../../../src/content/projects/professional/textmoji/thx.jpg";
import wip from "../../../../src/content/projects/professional/textmoji/wip.jpg";
import * as React from 'react';
export default {
  ZoomImage,
  XA,
  final,
  slack,
  thx,
  wip,
  React
};