import XA from 'components/ExternalAnchor';
import Hidden from 'components/Hidden';
import ZoomImage from 'components/ZoomImage';
import blue from "../../../../src/content/posts/2020/blue.jpg";
import blueZoom from "../../../../src/content/posts/2020/blue-zoom.jpg";
import bread from "../../../../src/content/posts/2020/bread.jpg";
import breadZoom from "../../../../src/content/posts/2020/bread-zoom.jpg";
import bike from "../../../../src/content/posts/2020/bike.jpg";
import bikeZoom from "../../../../src/content/posts/2020/bike-zoom.jpg";
import blm from "../../../../src/content/posts/2020/blm.jpg";
import blmZoom from "../../../../src/content/posts/2020/blm-zoom.jpg";
import bird from "../../../../src/content/posts/2020/bird.jpg";
import birdZoom from "../../../../src/content/posts/2020/bird-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  Hidden,
  ZoomImage,
  blue,
  blueZoom,
  bread,
  breadZoom,
  bike,
  bikeZoom,
  blm,
  blmZoom,
  bird,
  birdZoom,
  React
};