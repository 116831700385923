import ZoomImage from 'components/ZoomImage';
import XA from 'components/ExternalAnchor';
import screenshot from "../../../../src/content/projects/personal/they-linkedin/screenshot.jpg";
import screenshotZoom from "../../../../src/content/projects/personal/they-linkedin/screenshot-zoom.jpg";
import * as React from 'react';
export default {
  ZoomImage,
  XA,
  screenshot,
  screenshotZoom,
  React
};