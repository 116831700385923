import ZoomImage from 'components/ZoomImage';
import p4ktldr from "../../../../src/content/projects/personal/p4ktldr/p4ktldr.jpg";
import p4ktldrZoom from "../../../../src/content/projects/personal/p4ktldr/p4ktldr-zoom.jpg";
import p4ktldrWilco from "../../../../src/content/projects/personal/p4ktldr/p4ktldr-wilco.jpg";
import p4ktldrWilcoZoom from "../../../../src/content/projects/personal/p4ktldr/p4ktldr-wilco-zoom.jpg";
import pitchforkWilco from "../../../../src/content/projects/personal/p4ktldr/pitchfork-wilco.jpg";
import pitchforkWilcoZoom from "../../../../src/content/projects/personal/p4ktldr/pitchfork-wilco-zoom.jpg";
import * as React from 'react';
export default {
  ZoomImage,
  p4ktldr,
  p4ktldrZoom,
  p4ktldrWilco,
  p4ktldrWilcoZoom,
  pitchforkWilco,
  pitchforkWilcoZoom,
  React
};