import XA from 'components/ExternalAnchor';
import ZoomImage from 'components/ZoomImage';
import CountUp from 'components/CountUp';
import MarkdownRenderer from 'components/MarkdownRenderer';
import PreRainbowKnot from 'components/PreRainbowKnot';
import lake from "../../../../src/content/posts/react-in-markdown/lake-22.jpg";
import lakeZoom from "../../../../src/content/posts/react-in-markdown/lake-22-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  ZoomImage,
  CountUp,
  MarkdownRenderer,
  PreRainbowKnot,
  lake,
  lakeZoom,
  React
};