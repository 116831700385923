import XA from 'components/ExternalAnchor';
import ZoomImage from 'components/ZoomImage';
import ZoomCarousel from 'components/ZoomCarousel';
import joshuaTree from "../../../../src/content/posts/0822/joshua-tree.jpg";
import joshuaTreeZoom from "../../../../src/content/posts/0822/joshua-tree-zoom.jpg";
import slr from "../../../../src/content/posts/0822/slr.jpg";
import slrZoom from "../../../../src/content/posts/0822/slr-zoom.jpg";
import puffins from "../../../../src/content/posts/0822/puffins.jpg";
import puffinsZoom from "../../../../src/content/posts/0822/puffins-zoom.jpg";
import bugLight from "../../../../src/content/posts/0822/bug-light.jpg";
import bugLightZoom from "../../../../src/content/posts/0822/bug-light-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  ZoomImage,
  ZoomCarousel,
  joshuaTree,
  joshuaTreeZoom,
  slr,
  slrZoom,
  puffins,
  puffinsZoom,
  bugLight,
  bugLightZoom,
  React
};