import XA from 'components/ExternalAnchor';
import ZoomImage from 'components/ZoomImage';
import ZoomCarousel from 'components/ZoomCarousel';
import * as pics from "../../../../src/content/posts/2021/pics";
import * as React from 'react';
export default {
  XA,
  ZoomImage,
  ZoomCarousel,
  pics,
  React
};