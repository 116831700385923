import XA from 'components/ExternalAnchor';
import ZoomImage from 'components/ZoomImage';
import park from "../../../../src/content/posts/hey-presto-1/park.jpg";
import parkZoom from "../../../../src/content/posts/hey-presto-1/park-zoom.jpg";
import coney from "../../../../src/content/posts/hey-presto-1/coney.jpg";
import coneyZoom from "../../../../src/content/posts/hey-presto-1/coney-zoom.jpg";
import promenade from "../../../../src/content/posts/hey-presto-1/promenade.jpg";
import promenadeZoom from "../../../../src/content/posts/hey-presto-1/promenade-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  ZoomImage,
  park,
  parkZoom,
  coney,
  coneyZoom,
  promenade,
  promenadeZoom,
  React
};