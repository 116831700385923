import { useState, useEffect } from 'react';

// via https://stackoverflow.com/a/36862446/2777986
const getWindowDimensions = () => {
  if (typeof window === 'undefined') {
    return { width: undefined, height: undefined };
  }
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
