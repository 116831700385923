import ZoomImage from 'components/ZoomImage';
import XA from 'components/ExternalAnchor';
import hand from "../../../../src/content/projects/professional/roshambot/hand.jpg";
import handZoom from "../../../../src/content/projects/professional/roshambot/hand-zoom.jpg";
import pedestal from "../../../../src/content/projects/professional/roshambot/pedestal.jpg";
import pedestalZoom from "../../../../src/content/projects/professional/roshambot/pedestal-zoom.jpg";
import * as React from 'react';
export default {
  ZoomImage,
  XA,
  hand,
  handZoom,
  pedestal,
  pedestalZoom,
  React
};