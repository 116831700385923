import XA from 'components/ExternalAnchor';
import ZoomImage from 'components/ZoomImage';
import tulips from "../../../../src/content/posts/hey-presto-2/tulips.jpg";
import tulipsZoom from "../../../../src/content/posts/hey-presto-2/tulips-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  ZoomImage,
  tulips,
  tulipsZoom,
  React
};