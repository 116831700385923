import ZoomImage from 'components/ZoomImage';
import XA from 'components/ExternalAnchor';
import mapkinsMachine from "../../../../src/content/projects/professional/mapkins-machine/mapkins-machine.jpg";
import mapkinsMachineZoom from "../../../../src/content/projects/professional/mapkins-machine/mapkins-machine-zoom.jpg";
import * as React from 'react';
export default {
  ZoomImage,
  XA,
  mapkinsMachine,
  mapkinsMachineZoom,
  React
};