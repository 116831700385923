import ZoomImage from 'components/ZoomImage';
import XA from 'components/ExternalAnchor';
import animation from "../../../../src/content/projects/professional/carmen/animation.gif";
import map from "../../../../src/content/projects/professional/carmen/map.jpg";
import mapZoom from "../../../../src/content/projects/professional/carmen/map-zoom.jpg";
import * as React from 'react';
export default {
  ZoomImage,
  XA,
  animation,
  map,
  mapZoom,
  React
};