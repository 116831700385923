import XA from 'components/ExternalAnchor';
import Hidden from 'components/Hidden';
import ZoomImage from 'components/ZoomImage';
import end from "../../../../src/content/posts/2018/end.jpg";
import endZoom from "../../../../src/content/posts/2018/end-zoom.jpg";
import centuryComplete from "../../../../src/content/posts/2018/century-complete.jpg";
import centuryCompleteZoom from "../../../../src/content/posts/2018/century-complete-zoom.jpg";
import plantInRentalCar from "../../../../src/content/posts/2018/plant-in-rental-car.jpg";
import plantInRentalCarZoom from "../../../../src/content/posts/2018/plant-in-rental-car-zoom.jpg";
import reservoir from "../../../../src/content/posts/2018/reservoir.jpg";
import reservoirZoom from "../../../../src/content/posts/2018/reservoir-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  Hidden,
  ZoomImage,
  end,
  endZoom,
  centuryComplete,
  centuryCompleteZoom,
  plantInRentalCar,
  plantInRentalCarZoom,
  reservoir,
  reservoirZoom,
  React
};