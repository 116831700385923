import ZoomImage from 'components/ZoomImage';
import jasmine from "../../../../src/content/posts/lessons-in-stealing-my-boss-voice/jasmine.jpg";
import jasmineZoom from "../../../../src/content/posts/lessons-in-stealing-my-boss-voice/jasmine-zoom.jpg";
import jeff from "../../../../src/content/posts/lessons-in-stealing-my-boss-voice/jeff.jpg";
import jeffZoom from "../../../../src/content/posts/lessons-in-stealing-my-boss-voice/jeff-zoom.jpg";
import * as React from 'react';
export default {
  ZoomImage,
  jasmine,
  jasmineZoom,
  jeff,
  jeffZoom,
  React
};