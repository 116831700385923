import XA from 'components/ExternalAnchor';
import ZoomImage from 'components/ZoomImage';
import Hidden from 'components/Hidden';
import jan from "../../../../src/content/posts/2022/01-fireworks.jpg";
import janZoom from "../../../../src/content/posts/2022/01-fireworks-zoom.jpg";
import feb from "../../../../src/content/posts/2022/02-dove.jpg";
import febZoom from "../../../../src/content/posts/2022/02-dove-zoom.jpg";
import mar from "../../../../src/content/posts/2022/03-holly-cholla.jpg";
import marZoom from "../../../../src/content/posts/2022/03-holly-cholla-zoom.jpg";
import apr from "../../../../src/content/posts/2022/04-protho.jpg";
import aprZoom from "../../../../src/content/posts/2022/04-protho-zoom.jpg";
import may from "../../../../src/content/posts/2022/05-rowan-cake.jpg";
import mayZoom from "../../../../src/content/posts/2022/05-rowan-cake-zoom.jpg";
import jun from "../../../../src/content/posts/2022/06-fz.jpg";
import junZoom from "../../../../src/content/posts/2022/06-fz-zoom.jpg";
import jul from "../../../../src/content/posts/2022/07-acadia.jpg";
import julZoom from "../../../../src/content/posts/2022/07-acadia-zoom.jpg";
import aug from "../../../../src/content/posts/2022/08-puffins.jpg";
import augZoom from "../../../../src/content/posts/2022/08-puffins-zoom.jpg";
import sep from "../../../../src/content/posts/2022/09-blue.jpg";
import sepZoom from "../../../../src/content/posts/2022/09-blue-zoom.jpg";
import oct from "../../../../src/content/posts/2022/10-yose.jpg";
import octZoom from "../../../../src/content/posts/2022/10-yose-zoom.jpg";
import nov from "../../../../src/content/posts/2022/11-gator.jpg";
import novZoom from "../../../../src/content/posts/2022/11-gator-zoom.jpg";
import dec from "../../../../src/content/posts/2022/12-lathe.jpg";
import decZoom from "../../../../src/content/posts/2022/12-lathe-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  ZoomImage,
  Hidden,
  jan,
  janZoom,
  feb,
  febZoom,
  mar,
  marZoom,
  apr,
  aprZoom,
  may,
  mayZoom,
  jun,
  junZoom,
  jul,
  julZoom,
  aug,
  augZoom,
  sep,
  sepZoom,
  oct,
  octZoom,
  nov,
  novZoom,
  dec,
  decZoom,
  React
};