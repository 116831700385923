import { Link } from 'gatsby';
import ZoomImage from 'components/ZoomImage';
import XA from 'components/ExternalAnchor';
import ListProvider from 'content/posts/2019/ListProvider';
import ListSubset from 'content/posts/2019/ListSubset';
import ListLink from 'content/posts/2019/ListLink';
import gilardi from "../../../../src/content/posts/2019/gilardi.jpg";
import gilardiZoom from "../../../../src/content/posts/2019/gilardi-zoom.jpg";
import stormking from "../../../../src/content/posts/2019/stormking.jpg";
import stormkingZoom from "../../../../src/content/posts/2019/stormking-zoom.jpg";
import crj from "../../../../src/content/posts/2019/crj.jpg";
import crjZoom from "../../../../src/content/posts/2019/crj-zoom.jpg";
import * as React from 'react';
export default {
  Link,
  ZoomImage,
  XA,
  ListProvider,
  ListSubset,
  ListLink,
  gilardi,
  gilardiZoom,
  stormking,
  stormkingZoom,
  crj,
  crjZoom,
  React
};