import XA from "../../../../src/components/ExternalAnchor";
import ZoomImage from "../../../../src/components/ZoomImage";
import blue from "../../../../src/img/blue.jpg";
import blueZoom from "../../../../src/img/blue-zoom.jpg";
import * as React from 'react';
export default {
  XA,
  ZoomImage,
  blue,
  blueZoom,
  React
};