import ZoomImage from 'components/ZoomImage';
import XA from 'components/ExternalAnchor';
import lake from "../../../../src/content/posts/react-in-mdx/lake.jpg";
import lakeZoom from "../../../../src/content/posts/react-in-mdx/lake-zoom.jpg";
import * as React from 'react';
export default {
  ZoomImage,
  XA,
  lake,
  lakeZoom,
  React
};