import ZoomImage from 'components/ZoomImage';
import XA from 'components/ExternalAnchor';
import staticPic from "../../../../src/content/projects/professional/color-composer/static.jpg";
import staticPicZoom from "../../../../src/content/projects/professional/color-composer/static-zoom.jpg";
import canvas from "../../../../src/content/projects/professional/color-composer/canvas.jpg";
import canvasZoom from "../../../../src/content/projects/professional/color-composer/canvas-zoom.jpg";
import * as React from 'react';
export default {
  ZoomImage,
  XA,
  staticPic,
  staticPicZoom,
  canvas,
  canvasZoom,
  React
};